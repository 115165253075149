import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Button, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import DashboardService from "../../services/dashboard.service";
import CommonService from "../../services/common.service";

const RoundModal = (props) => {
  const [activeTab, setActiveTab] = useState("round"); // Initial active tab
  const [lockoutList, setLockoutList] = useState([]); // Initial active tab
  const [currentTradeWindowDetails, setCurrentTradeWindowDetails] = useState(
    {}
  );
  const [nextTradeWindowDetails, setNextTradeWindowDetails] = useState({}); // Initial active tab
  // Initial active tab
  // currentRoundDetails
  const round_data = props.round_data;
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    if (tab === "trade") {
      DashboardService.getTradingListing()
        .then((res) => {
          setLockoutList(res.data);
        })
        .then(() => {
          CommonService.getTradingWindows().then((res) => {
            setCurrentTradeWindowDetails(res.currentTradeWindowDetails);
            setNextTradeWindowDetails(res.nextTradeWindowDetails);
          });
        });
    }
  };

  return (
    <>
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
      >
        <Modal.Header>Rounds & Trade Windows</Modal.Header>
        <Modal.Body className="roundpopup">
          {/* <Tabs
            id="round-modal-tabs"
            activeKey={activeTab}
            variant="tabs"
            onSelect={handleTabSelect}
          >
            <Tab eventKey="round" title="Rounds">
              <div class="datefilter">
                <ul className="currentround">
                  <li>
                    {props.currentRoundDetails && (
                      <div class="form-group startdate">
                        <label>
                          {" "}
                          {props.isCurrent === true ? " Current " : "Previous"}
                        </label>
                        <span>
                          {props.currentRoundDetails?.round} (
                          {moment(props.currentRoundDetails?.start_date).format(
                            "MMM D, h:mma"
                          )}{" "}
                          -{" "}
                          {moment(props.currentRoundDetails?.end_date).format(
                            "MMM D, h:mma"
                          )}
                          )
                        </span>
                      </div>
                    )}
                  </li>
                  <li>
                    <div class="form-group enddate">
                      {props.nextRoundDetails && (
                        <>
                          <label>Next</label>
                          <span>
                            {" "}
                            Next : R{props.nextRoundDetails?.round}(
                            {moment(props.nextRoundDetails?.start_date).format(
                              "MMM D, h:mma"
                            )}{" "}
                            -{" "}
                            {moment(props.nextRoundDetails?.end_date).format(
                              "MMM D, h:mma"
                            )}
                            )
                          </span>
                        </>
                      )}

                      {!props.nextRoundDetails && (
                        <>
                          <label> Next</label> <span> Completed</span>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </div>

              <div className="pointsystem-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Round</th>
                      <th>Start Day</th>
                      <th>End Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {round_data &&
                      round_data.map((item, key) => (
                        <tr key={key}>
                          <td>{item.round}</td>
                          <td>{moment(item.start_date).format("D.MM.YYYY")}</td>
                          <td>{moment(item.end_date).format("D.MM.YYYY")}</td>
                          <td>{moment(item.start_date).format("h:mm A")}</td>
                          <td>{moment(item.end_date).format("h:mm A")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab
              eventKey="trade"
              title="Trade Windows"
              style={{ maxHeight: "500px", overflowY: "auto" }}
            >
              {currentTradeWindowDetails && (
                <>
                  <div className="pt-2">
                    <strong>
                      Current22: TW{currentTradeWindowDetails?.round}
                    </strong>{" "}
                    (
                    {moment(currentTradeWindowDetails.trade_start).format(
                      "MMM D, h:mma"
                    )}{" "}
                    -{" "}
                    {moment(currentTradeWindowDetails.trade_close).format(
                      "MMM D, h:mma"
                    )}
                    )
                  </div>
                </>
              )}
              {nextTradeWindowDetails && (
                <>
                  <div className="pt-2">
                    <strong> Next: TW{nextTradeWindowDetails?.round}</strong> (
                    {moment(nextTradeWindowDetails.trade_start).format(
                      "MMM D, h:mma"
                    )}{" "}
                    -{" "}
                    {moment(nextTradeWindowDetails.trade_close).format(
                      "MMM D, h:mma"
                    )}
                    )
                  </div>
                </>
              )}
              <div className="pointsystem-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Trade Window</th>
                      <th>Start Day</th>
                      <th>End Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lockoutList &&
                      lockoutList.map((item, key) => (
                        <tr key={key}>
                          <td>{++key}</td>
                          <td>
                            {moment(item.trade_start).format("D.MM.YYYY")}
                          </td>
                          <td>
                            {moment(item.trade_close).format("D.MM.YYYY")}
                          </td>
                          <td>{moment(item.trade_start).format("h:mm A")}</td>
                          <td>{moment(item.trade_close).format("h:mm A")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs> */}

          <ul class="nav tradetab nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="Overall-tab"
                data-bs-toggle="tab"
                data-bs-target="#Overall"
                type="button"
                role="tab"
                aria-controls="Overall"
                aria-selected="true"
              >
                Rounds
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="Gameweek-tab"
                data-bs-toggle="tab"
                data-bs-target="#Gameweek"
                type="button"
                role="tab"
                aria-controls="Gameweek"
                aria-selected="true"
              >
                Trade Windows
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="Overall"
              role="tabpanel"
              aria-labelledby="Overall-tab"
            >
              <div class="datefilter">
                <ul className="currentround">
                  {props.currentRoundDetails && (
                    <li>
                      <div class="form-group startdate">
                        <label>
                          {" "}
                          {props.isCurrent === true ? " Current " : "Previous"}
                        </label>
                        <span>
                          R{props.currentRoundDetails?.round + " "}(
                          {moment(props.currentRoundDetails?.start_date).format(
                            "MMM D, h:mma"
                          )}{" "}
                          -{" "}
                          {moment(props.currentRoundDetails?.end_date).format(
                            "MMM D, h:mma"
                          )}
                          )
                        </span>
                      </div>
                    </li>
                  )}
                  <li>
                    <div class="form-group enddate">
                      {props.nextRoundDetails && (
                        <>
                          <label>Next</label>
                          <span>
                            {" "}
                            Next : R{props.nextRoundDetails?.round + " "}(
                            {moment(props.nextRoundDetails?.start_date).format(
                              "MMM D, h:mma"
                            )}{" "}
                            -{" "}
                            {moment(props.nextRoundDetails?.end_date).format(
                              "MMM D, h:mma"
                            )}
                            )
                          </span>
                        </>
                      )}

                      {!props.nextRoundDetails && (
                        <>
                          <label> Next</label> <span> Completed</span>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              {/* Content for the "Round" tab goes here */}
              <div className="pointsystem-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Round</th>
                      <th>Start Day</th>
                      <th>End Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {round_data &&
                      round_data.map((item, key) => (
                        <tr key={key}>
                          <td>{item.round}</td>
                          <td>{moment(item.start_date).format("D.MM.YYYY")}</td>
                          <td>{moment(item.end_date).format("D.MM.YYYY")}</td>
                          <td>{moment(item.start_date).format("h:mm A")}</td>
                          <td>{moment(item.end_date).format("h:mm A")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="Gameweek"
              role="tabpanel"
              aria-labelledby="Gameweek-tab"
            >
              <div className="datefilter">
                <ul className="currentround">
                  {currentTradeWindowDetails && (
                    <li>
                      <div class="form-group startdate">
                        <label>
                          {" "}
                          {props.isCurrent === true ? " Current " : "Previous"}
                        </label>
                        <span>
                          {currentTradeWindowDetails?.round}
                          {moment(currentTradeWindowDetails.trade_start).format(
                            "MMM D, h:mma"
                          )}{" "}
                          -{" "}
                          {moment(currentTradeWindowDetails.trade_close).format(
                            "MMM D, h:mma"
                          )}
                        </span>
                      </div>
                    </li>
                  )}
                  <li>
                    <div class="form-group enddate">
                      {nextTradeWindowDetails && (
                        <>
                          <label>Next</label>
                          <span>
                            {nextTradeWindowDetails?.round}
                            {moment(nextTradeWindowDetails.trade_start).format(
                              "MMM D, h:mma"
                            )}{" "}
                            -{" "}
                            {moment(nextTradeWindowDetails.trade_close).format(
                              "MMM D, h:mma"
                            )}
                          </span>
                        </>
                      )}

                      {!nextTradeWindowDetails && (
                        <>
                          <label> Next</label> <span> Completed</span>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
              <div className="pointsystem-table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Trade Window</th>
                      <th>Start Day</th>
                      <th>End Day</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lockoutList &&
                      lockoutList.map((item, key) => (
                        <tr key={key}>
                          <td>{++key}</td>
                          <td>
                            {moment(item.trade_start).format("D.MM.YYYY")}
                          </td>
                          <td>
                            {moment(item.trade_close).format("D.MM.YYYY")}
                          </td>
                          <td>{moment(item.trade_start).format("h:mm A")}</td>
                          <td>{moment(item.trade_close).format("h:mm A")}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="poweredby">
            Powered By
            <img src="/images/logo.png" alt="img" />
          </div>
          <div className="">
            <Link
              className="border-btn"
              onClick={() => props.setVisible(false)}
            >
              Close
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RoundModal;
